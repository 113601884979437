import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import SignupForm from './SignupForm/SignupForm';
import { notification } from 'antd';
import { UserContext } from '../../App';
import firebase from 'firebase/app';
import 'firebase/auth';
import './Signup.scss';

function Signup(){
    const {userAuth, setUserAuth} = useContext(UserContext);
    const [authType, setAuthType] = useState('Sign Up');
    const [errorVisible, setErrorVisibility] = useState(false);

    const [errorContent, setErrorContent] = useState({
      title: '',
      message: ''
    })

    const [signupText, setSignupText] = useState('CREATE AN ACCOUNT');
    
    useEffect(() => {
      if (errorVisible) {
        openNotificationWithIcon('error')
      }
    }, [errorVisible])

    const openNotificationWithIcon = type => {
      notification[type]({
        message: errorContent.title,
        description: errorContent.message,
      });

      setSignupText('CREATE AN ACCOUNT');

      setTimeout(() => {
        setErrorVisibility(false);
      },5000)
    };

    const history = useHistory();

    const signUpWithFirebase = ({email, password, name}) => {
      firebase.auth().createUserWithEmailAndPassword(email, password)
      .then(() => {
        console.log('User signed up');

        let user = firebase.auth().currentUser;
        user.updateProfile({
            displayName: name
        })
        .then(response=>{
          console.log('wrapped up-> ', response)
        })
        .catch(error=>{
          console.log('error message: ', error.message)
        })
        setUserAuth(true);

        history.push('/dashboard');
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        setErrorContent({
          'title': 'Error creating account',
          'message': errorMessage
        }) 

        setErrorVisibility(true);
       
        console.log(`Error creating user | Code: ${errorCode} | Message: ${errorMessage}`);
      });
    }
          
    return (
        <div className = "auth-page">
            <div className="auth-modal">
                <h2>{authType}</h2>
                <SignupForm 
                  setErrorContent = { setErrorContent } 
                  setErrorVisibility = { setErrorVisibility } 
                  signUpWithFirebase = {signUpWithFirebase}
                  signupText = { signupText }
                  setSignupText = { setSignupText }
                />
            </div>
            <p className="change-auth-text">Already have an account?  <Link to="/login" className="hyperlink-text">Log In</Link></p>
        </div>
    )
}

export default Signup;