import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebase from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyDLcJrUOubXBgT1i1ks_8iB1ZzCSUKqHHc",
  authDomain: "i-am-very-ooo-demo-19774.firebaseapp.com",
  databaseURL: "https://i-am-very-ooo-demo-19774.firebaseio.com",
  projectId: "i-am-very-ooo-demo-19774",
  storageBucket: "i-am-very-ooo-demo-19774.appspot.com",
  messagingSenderId: "873725470031",
  appId: "1:873725470031:web:4de43a547e133c9f77513a",
  measurementId: "G-WLEWGW2YXZ"
};

firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
