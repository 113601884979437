import React from 'react';
import { Form, Input, Button } from 'antd';

function LoginForm({setErrorContent, setErrorVisibility, loginWithFirebase, loginText, setLoginText}) {
    const layout = {
        labelCol: {
          span: 16,
        },
        wrapperCol: {
          span: 24,
        },
    };

    const tailLayout = {
      wrapperCol: {
        offset: 0,
        span: 24,
      },
    };

    const onFinish = (values) => {
        setLoginText('LOADING...');
        console.log('Success:', values);

        loginWithFirebase({
          email: values.email,
          password: values.password
        })
    };
    
    const onFinishFailed = (errorInfo) => {
        setLoginText('SIGN IN');
        console.log('Failed:', errorInfo);

        setErrorContent({
          'title': 'Error with log in',
          'message': errorInfo
        })

        setErrorVisibility(true);
    };
    
    return (
        <Form
          {...layout}
          layout = 'vertical'
          name="basic"
          size='large'
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="registration-form"
        >
          <Form.Item
            label="Email"
            name="email"
            className="input"
            rules={[
              {
                required: true,
                message: 'Please input your email!',
              },
            ]
            
            }
          >
            <Input />
          </Form.Item>
    
          <Form.Item
            label="Password"
            name="password"
            className="input"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
    
          <Form.Item {...tailLayout}>
            <Button type="primary" shape = "round" htmlType="submit" className="submit-btn">
              {loginText}
            </Button>
          </Form.Item>
        </Form>
    );
}

export default LoginForm;