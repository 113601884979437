import React, { useContext, useEffect } from 'react';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Menu } from 'antd';
import { UserContext } from '../../App';
import firebase from "firebase/app";
import 'firebase/auth';
import './NavBar.scss';

function NavBar(){
    const {userAuth, setUserAuth, userInfo, setUserInfo, infoLoading, setInfoLoading} = useContext(UserContext);

    const handleMenuClick = e => {
        if (e.key == 'log-out') {
            firebase.auth().signOut().then(function() {
                console.log('User signed out');
            }).catch (function(error) {
                console.log(error);
            });
        }
    }

    useEffect(() => {
        setInfoLoading(false)
    },[userInfo])

    const { SubMenu } = Menu;

    return(
        <div className="navbar-component">
            <div className="left-container">
                <h1>OOO admin</h1>
            </div>
            {
                userAuth 
                    ? (
                        <div className='right-container'>
                            { infoLoading 
                                ? null
                                :(
                                    <>
                                        <Menu mode = 'horizontal' className = 'username' onClick = {handleMenuClick}>
                                            <SubMenu icon={<DownOutlined />}  title={userInfo.firstName} key='username-menu'>
                                            <Menu.Item key='log-out'>Log Out</Menu.Item> 
                                            </SubMenu>
                                        </Menu>
                                    </>
                                )
                            }
                            <Avatar size = {50} icon = {<UserOutlined />} />
                        </div>
                    )
                    : (
                        <div className="right-container">
                        </div>
                    )
            }
            
        </div>
    )
}

export default NavBar;