import React, { useState, createContext, useEffect } from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect }  from 'react-router-dom';
import './App.css';
import firebase from 'firebase/app';
import 'firebase/auth';
import Signup from './scenes/Signup/Signup';
import Login from './scenes/Login/Login';
import NavBar from './components/NavBar/NavBar';
import Dashboard from './scenes/Dashboard/Dashboard';

export const UserContext = createContext();

function App() {
  const [userAuth, setUserAuth] = useState(false)   // Boolean with the auth status of the user
  const [userInfo, setUserInfo] = useState({        // Object with the user information
      firstName: '',
      lastName: ''
  })      

  const [infoLoading, setInfoLoading] = useState(false)

  const loadName = () => {
    let user = firebase.auth().currentUser;
    if (user.displayName) {
      const splitName = user.displayName.split(" ");
      setUserInfo({
        firstName: splitName[0],
        lastName: splitName[1]
      })
    }
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUserAuth(true);
        loadName();
      } 

      else {
        setUserAuth(false);
      }
    });  
  }, [userAuth])

  return (
    <div className="App">
      <UserContext.Provider value = {{userAuth, setUserAuth, userInfo, setUserInfo, infoLoading, setInfoLoading, loadName }}>
        <Router>
          <NavBar />
          <Switch>
            {
              userAuth
              ? (
                <>
                  <Route exact path='/'>
                    <Redirect to = '/dashboard' />
                  </Route>
                  <Route exact path='/login'>
                    <Redirect to = '/dashboard' />
                  </Route>
                  <Route exact path='/signup'>
                    <Redirect to = '/dashboard' />
                  </Route>
                  <Route exact path='/dashboard' component = { Dashboard } />
                </>
              )
              : 
                (
                  <>
                    <Route exact path='/'>
                      <Redirect to = '/login' />
                    </Route>
                    <Route exact path='/dashboard'>
                      <Redirect to = '/login' />
                    </Route>
                    <Route exact path='/signup' component = { Signup } />
                    <Route exact path='/login' component = { Login } />
                  </>
                )
            }
          </Switch>
        </Router>
      </UserContext.Provider>
    </div>
  );
}

export default App;
