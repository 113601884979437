import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import LoginForm from './LoginForm/LoginForm';
import { notification } from 'antd';
import firebase from 'firebase/app';
import 'firebase/auth';
import './Login.scss';

function Login() {
    const [loginText, setLoginText] = useState('SIGN IN');
    const [errorVisibility, setErrorVisibility] = useState(false);
    
    const [errorContent, setErrorContent] = useState({
      'title': '',
      'message': ''
    })

    useEffect(() => {
      if(errorVisibility){
        openNotificationWithIcon('error');
      }
    },[errorVisibility])

    const openNotificationWithIcon = type => {
      notification[type]({
        message: errorContent.title,
        description: errorContent.message,
      });

      setLoginText('SIGN IN')

      setTimeout(()=>{
        setErrorVisibility(false)
      },5000)
    };

    const loginWithFirebase = ({email, password}) => {
      firebase.auth().signInWithEmailAndPassword(email, password)
      .then(() => {
        console.log('User signed in');
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        setErrorContent({
          'title': 'Error in Sign In',
          'message': errorMessage
        })

        setErrorVisibility(true);

        console.log(`Error in login | Code: ${errorCode} | Message: ${errorMessage}`);
      });
    }

    return (
        <div className="login-page">
            <div className="auth-modal">
                <h2>Log In</h2>
                <LoginForm 
                  setErrorContent = { setErrorContent }
                  setErrorVisibility = { setErrorVisibility } 
                  loginWithFirebase = { loginWithFirebase }
                  loginText = { loginText }
                  setLoginText = { setLoginText }
                />
            </div>
            <p>Don't have an account? <Link to='/signup' className='hyperlink-text'>Sign Up</Link></p>
        </div>
    )
}

export default Login