import React, {useState, useEffect} from 'react';
import DashboardList from './DashboardList/DashboardList';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';

function Dashboard() {
  const [loading, setLoading] = useState(true)                       // Handles when the system is fetching data
  const [applicants, setApplicants] = useState([]);                  // Stores the list of applicants 
  const [applicantsStatus, setApplicantsStatus] = useState({});      // Stores the status of the applicants (active - disabled - pending - waiting)

  const loadApplicants = () => {
    setLoading(true);

    const getApplicants = firebase.functions().httpsCallable('getApplicants');

    getApplicants ({
    }).then(result => {
        let applicantsArray = [];
        let status = {};

        result.data.forEach(applicant => {
          applicantsArray.push(applicant.email);
          status[applicant.email] = applicant.status;
        })

        setApplicants(applicantsArray);
        setApplicantsStatus({...status});
        setLoading(false);
    }).catch(error => {
        console.log(error);
    })
  }

  useEffect(() => {
    loadApplicants();
  },[])

  const handleActivation = async (applicant) => {
    const activateUser = firebase.functions().httpsCallable('activateUser');

    activateUser ({
        email: applicant 
    }).then(result => {
        if (result.data === 'Success') {
          applicantsStatus[applicant] = 'active';
          setApplicantsStatus({...applicantsStatus});
        }

        else if (result.data === 'Waiting for user to sign in') {
          applicantsStatus[applicant] = 'waiting';
          setApplicantsStatus({...applicantsStatus});
        }
        
        else {
          window.alert(result.data);
        }
    }).catch(error => {
        console.log(error);
        window.alert(error.message);
    })
  } 

  const handleDeactivation = async (applicant) => {
    const disableUser = firebase.functions().httpsCallable('disableUser');

    disableUser ({
        email: applicant 
    }).then(result => {
        if (result.data === 'Success') {
          applicantsStatus[applicant] = 'disabled';
          setApplicantsStatus({...applicantsStatus});
        }

        else {
          window.alert(result.data);
        }
    }).catch(error => {
        console.log(error);
        window.alert(error.message);
    })
  } 

  const handleElimination = async (applicant) => {
    const deleteUser = firebase.functions().httpsCallable('deleteUser');

    deleteUser ({
        email: applicant 
    }).then(result => {
        if (result.data === 'Success') {
          for (let i = 0; i < applicants.length; i++) {
            const applicantEmail = applicants[i];
      
            if (applicant === applicantEmail) {
                applicants.splice(i, 1);
                setApplicants([...applicants]);
                break 
            }
          }
        }

        else {
          window.alert(result.data);
        }
    }).catch(error => {
        console.log(error);
        window.alert(error.message);
    })
  } 

  const handleSignInEmail = async (applicant) => {
    const resendSignInEmail = firebase.functions().httpsCallable('resendSignInEmail');

    resendSignInEmail ({
        email: applicant 
    }).then(result => {
        if (result.data === 'Success') {
          console.log(result);
        }

        else {
          window.alert(result.data);
        }
    }).catch(error => {
        console.log(error);
        window.alert(error.message);
    })
  } 
  
  return (
    <DashboardList 
      loading = {loading}
      applicants = { applicants }
      applicantsStatus = { applicantsStatus } 
      handleActivation = { handleActivation }
      handleDeactivation = { handleDeactivation }
      handleElimination = { handleElimination }
      handleSignInEmail = { handleSignInEmail }
    />
  );
}

export default Dashboard;

