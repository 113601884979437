import React from 'react';
import { List, Divider, Button, Popconfirm, Space, Skeleton} from 'antd';
import { CheckCircleTwoTone, StopTwoTone, ExclamationCircleTwoTone, ClockCircleTwoTone} from '@ant-design/icons';
import './DashboardList.scss';

function DashboardList({loading, applicants, applicantsStatus, handleActivation, handleDeactivation, handleElimination, handleSignInEmail}) {
    
    const Buttons = (item, activateStatus, disableStatus, deleteStatus, resendStatus) => {
        return (
            <Space>
                <Popconfirm
                    title="Are you sure to activate this user?"
                    onConfirm={ () => handleActivation(item) }
                    okText="Yes"
                    cancelText="No"
                >
                    <Button className='normal-buttons'
                    type='primary'
                    disabled={ activateStatus }
                    >
                    Activate 
                    </Button>
                </Popconfirm>
                <Popconfirm
                    title="Are you sure to disable this user?"
                    onConfirm={ () => handleDeactivation(item) }
                    okText="Yes"
                    cancelText="No"
                >
                    <Button className='normal-buttons'
                    type='primary'
                    disabled={ disableStatus }
                    >
                    Disable
                    </Button>
                </Popconfirm>
                <Popconfirm
                    title="Are you sure to delete this user? This will delete all the user information."
                    onConfirm={ () => handleElimination(item) }
                    okText="Yes"
                    cancelText="No"
                >
                    <Button className='danger-buttons'
                    danger
                    type='primary'
                    disabled={ deleteStatus }
                    >
                    Delete
                    </Button>
                </Popconfirm>
                <Popconfirm
                    title="Are you sure to resend the sign-in link?"
                    onConfirm={ () => handleSignInEmail(item) }
                    okText="Yes"
                    cancelText="No"
                >
                    <Button className='normal-buttons'
                    type='primary'
                    disabled={ resendStatus }
                    >
                    Resend sign-in link 
                    </Button>
                </Popconfirm>
            </Space>
        );
    }

    return (
        <>
        <div className='dashboard-page'>
        <Divider orientation='center'>APPLICANTS</Divider>
        { loading ? 
            (
                <>
                <Skeleton.Input active className='loader-skeleton'/>
                <Skeleton.Input active className='loader-skeleton'/>  
                <Skeleton.Input active className='loader-skeleton'/>   
                </>
            ) :
            <List className='dashboard-list'
                bordered
                dataSource={ applicants }
                renderItem={ item => (
                <List.Item>
                    { applicantsStatus[item] === 'active' ? 
                    (
                        <Space size='large' className='item-container'>
                            <Space className='email-icon-container'>
                                { item }
                                <CheckCircleTwoTone twoToneColor='#52c41a' />
                            </Space>
                            { Buttons(item, true, false, false, true) }
                        </Space>
                    ) : 
                    (
                        applicantsStatus[item] === 'disabled' ? 
                        (
                            <Space size='large' className='item-container'>
                                <Space className='email-icon-container'>
                                    { item }
                                    <StopTwoTone twoToneColor='#FF3333' />
                                </Space>
                                { Buttons(item, false, true, false, true) }
                            </Space>
                        ) : 
                        (
                            applicantsStatus[item] === 'pending' ?
                            (
                                <Space size='large' className='item-container'>
                                    <Space className='email-icon-container'>
                                        { item }
                                        <ExclamationCircleTwoTone twoToneColor='#FF9933' />
                                    </Space>
                                    { Buttons(item, false, true, false, true) }
                                </Space>
                            ) :
                            (
                                <Space size='large' className='item-container'>
                                    <Space className='email-icon-container'>
                                        { item }
                                        <ClockCircleTwoTone twoToneColor='#3363FF' />
                                    </Space>
                                    { Buttons(item, true, true, true, false) }
                                </Space>
                            )
                        )
                    )
                    }
                </List.Item>
                )}
            />
            }
            </div>
        </>
    );
}

export default DashboardList;